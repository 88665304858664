/*eslint-disable */
import toast from '@/services/toast';
import Multiselect from 'vue-multiselect'
import allCountries from '@/services/phoneCodeList.json';
import {
  bus
} from '@/main';
export default {
  name: 'about-of-user-info',
  components: {
    Multiselect
  },
  props: [
    'updateAboutPopupKeySTATE'
  ],
  data () {
    return {
      submitted:false,
      professionLabel:[
        {name:'General Physician/Family Doctor', code:'General Physician/Family Doctor'},
        {name:'Dentist', code:'Dentist'},
        {name:'Gynecologist', code:'Gynecologist'},
        {name:'Oncologist', code:'Oncologist'},
        {name:'Pediatrician/Child Specialist', code:'Pediatrician/Child Specialist'},
        {name:'Cardiologist', code:'Cardiologist'},
        {name:'ENT Specialist', code:'ENT Specialist'},
        {name:'Psychiatrist and Neurologists', code:'Psychiatrist and Neurologists'},
        {name:'Pulmonologist', code:'Pulmonologist'},
        {name:'Surgeon', code:'Surgeon'},
        {name:'Endocrinologist', code:'Endocrinologist'},
        {name:'Other Doctors and Specialists', code:'Other Doctors and Specialists'},
      ],
      patientDiseasesLalbel:[
        {name:'Non-Hodgin’s Lymphoma', code:'Non-Hodgin’s Lymphoma'},
        {name:'ALL', code:'ALL'},
        {name:'AML', code:'AML'},
        {name:'Squamous Cell Carcinoma', code:'Squamous Cell Carcinoma'},
        {name:'Coronary artery bypass grafting', code:'Coronary artery bypass grafting'},
        {name:'neurosurgery', code:'neurosurgery'},
        {name:'Cardiovascular Surgery', code:'Cardiovascular Surgery'},
        {name:'COPD', code:'COPD'},
        {name:'Other', code:'Other'},
      ],
      clinicalIntersetLabel:[
        {name:'Primary Philadelphia chromosome positive acute myeloid leukaemia', code:'Primary Philadelphia chromosome positive acute myeloid leukaemia'},
        {name:'heterogeneous molecular grade of DLBCL with MYC', code:'heterogeneous molecular grade of DLBCL with MYC'},
        {name:'BCL2 double-hit', code:'BCL2 double-hit'},
        {name:'mucosal signet-ring cell carcinoma', code:'mucosal signet-ring cell carcinoma'}
      ],
      surgicalIntersetLabel:[
        {name:'Surgical Resection', code:'Surgical Resection'}
      ],
      researchAreasLabel:[
        {name:'Cancer Genetics & Genomics', code:'Cancer Genetics & Genomics'},
        {name:'Cancer Immunotherapy & Vaccines', code:'Cancer Immunotherapy & Vaccines'},
        {name:'Cancer Metabolism', code:'Cancer Metabolism'},
        {name:'Cell Signalling & Regulation', code:'Cell Signalling & Regulation'},
        {name:'Computational Oncology', code:'Computational Oncology'},
        {name:'Medical Machine Learning', code:'Medical Machine Learning'},
        {name:'Medical AI', code:'Medical AI'},
        {name:'Biomedical Engineering', code:'Biomedical Engineering'},
        {name:'AI', code:'AI'},
        {name:'ML', code:'ML'},
        {name:'Other', code:'Other'}
      ],
      workAssociatedLabel:[
        {name:'Memorial Sloan Kettering Hospital', code:'Memorial Sloan Kettering Hospital'},
        {name:'NYU Langone', code:'NYU Langone'},
        {name:'Tata Medical Centre', code:'Tata Medical Centre'},
        {name:'Centre for Disease Control (CDC)', code:'Centre for Disease Control (CDC)'}
      ],
      dgreeLabel:[
        {name:'MD', code:'MD'},
        {name:'Ph.D.', code:'Ph.D.'},
        {name:'M.D. & Ph.D.', code:'M.D. & Ph.D.'},
        {name:'M.S.', code:'M.S.'},
        {name:'M.Ch.', code:'M.Ch.'},
        {name:'MBBS', code:'MBBS'},
        {name:'DM', code:'DM'},
        {name:'DNB', code:'DNB'},
        {name:'M.Sc.', code:'M.Sc.'},
        {name:'MBA', code:'MBA'}
      ],
      medicalProExperienceLabel:[
        {name:'clinical', code:'clinical'},
        {name:'surgical', code:'surgical'},
        {name:'medical physics', code:'medical physics'},
        {name:'nuclear medicine', code:'nuclear medicine'},
        {name:'pharmacological', code:'pharmacological'},
        {name:'medical research', code:'medical research'},
        {name:'biochemistry research', code:'biochemistry research'},
        {name:'cancer research', code:'cancer research'},
        {name:'medical AI', code:'medical AI'},
        {name:'AI', code:'AI'}
      ],
      languageProficiencyLabel:[
        {name:'English', code:'English'},
        {name:'French', code:'French'},
        {name:'Hindi', code:'Hindi'},
        {name:'Mandarin', code:'Mandarin'},
        {name:'German', code:'German'},
        {name:'Japanese', code:'Japanese'},
        {name:'Korean', code:'Korean'},
        {name:'Italian', code:'Italian'},
        {name:'Cantonese', code:'Cantonese'},
        {name:'Spanish', code:'Spanish'},
        {name:'Bengali', code:'Bengali'},
        {name:'Tamil', code:'Tamil'},
        {name:'Gujrati', code:'Gujrati'},
        {name:'Marathi', code:'Marathi'},
        {name:'Telegu', code:'Telegu'},
        {name:'Malayalam', code:'Malayalam'},
        {name:'Punjabi', code:'Punjabi'},
        {name:'Thai', code:'Thai'},
        {name:'Hebrew', code:'Hebrew'},
        {name:'Arabic', code:'Arabic'},
        {name:'Swahili', code:'Swahili'},
        {name:'Amharic', code:'Amharic'},
        {name:'Russian', code:'Russian'},
        {name:'Bahasa', code:'Bahasa'},
        {name:'Malay', code:'Malay'},
        {name:'Portugese', code:'Portugese'},
      ],
      professionValue:[],
      patientDiseasesValue:[],
      clinicalIntersetValue:[],
      surgicalIntersetValue:[],
      researchAreasValue:[],
      workAssociatedValue:[],
      dgreeValue:[],
      medicalProExperienceValue:[],
      languageProficiencyValue:[],
      sendpatientDiseasesValue:[],
      sendResearchAreasValue:[],
      sendLanguageProficiencyValue:[],
      medicalProExperienceNamber:'',
      first_name:'',
      last_name:'',
      email:'',
      gender:'',
      dob:'',
      allCountriesList: [],
      enabled_other_d_cat:false,
      other_doctor_cat:'',
      memberAllFilterList:null,
      memberAllFilterListResults:[],
      areaOfSpecialization:'',
      phone: {
        number: '',
        isValid: false,
        country: '',
        countryIso: ''
      },
      open:false,
      confirm_password:'',
      password:'',

    }
  },
  computed: {
  },
  mounted () {
    this.getUserCountry();
    this.getProfessionCategoriesListMethods();  
    // loop over all of the countries above, restructuring the data to be objects with named keys
    for (var i = 0; i < allCountries.length; i++) {
      let c = allCountries[i];
      this.allCountriesList.push({
        name: c[0],
        iso2: c[1],
        dialCode: c[2],
        priority: c[3] || 0,
        areaCodes: c[4] || null
      });
    }
  },
  methods: {
    getUserCountry() {
      var self = this;
      fetch('https://ip2c.org/s')
        .then(function(response) {
          return response.text();
        })
        .then(function(text) {
          if (!text || "1" !== text[0])
            throw new Error("unable to fetch the country");
          if (self.phone.country == '') {
            self.phone.countryIso = (text.substr(2, 2)).toLowerCase();
            self.phone.country = self.allCountriesList.filter(item => item.iso2 == self.phone.countryIso)[0].dialCode;
          }
        })
        .catch(function(error) {
          console.log('Request failed', error)
        });
    },
    chooseCountry(country) {
      this.phone.countryIso = country.iso2;
      this.phone.country = country.dialCode;
    },
    clickedOutside() {
      this.open = false;
    },
    toggleDropdown() {
      this.open = !this.open;
    },
    getProfessionCategoriesListMethods(){
      this.$store.dispatch('getMemberFilterListForSignupFun')
        .then((res) => {  
          this.memberAllFilterList = res.data
          if(this.memberAllFilterList){
            this.memberAllFilterListResults = this.memberAllFilterList.data
          }
        }).catch((err)=>{
        })
    },
    addTagOfprofession (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.professionValue = []
      this.professionLabel.push(tag)
      this.professionValue.push(tag)
    },
    addTagOfResearchAreas (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.researchAreasLabel.push(tag)
      this.researchAreasValue.push(tag)
    },
    addTagOfDgree (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.dgreeValue = []
      this.dgreeLabel.push(tag)
      this.dgreeValue.push(tag)
    },
    addTagOfLanguageProficiency (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.medicalProExperienceLabel.push(tag)
      this.languageProficiencyValue.push(tag)
    },
    updateAboutBeforeSubmitMethod(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted =  true
          for(var i = 0; i < this.researchAreasValue.length; i++){
            this.sendResearchAreasValue.push(this.researchAreasValue[i].name)
          }
          for(var i = 0; i < this.languageProficiencyValue.length; i++){
            this.sendLanguageProficiencyValue.push(this.languageProficiencyValue[i].name)
          }
          var sendProfessionValue
          var sendProfessionalFilterName
          var sendDgreeValue
          var sendProfessionalCategory
          if(this.areaOfSpecialization.category){
            sendProfessionalCategory = this.areaOfSpecialization.member_category.filter_key
            sendProfessionalFilterName = this.areaOfSpecialization.category
          }
          if(this.professionValue.name){
            sendProfessionValue = this.professionValue.name
          }else{
            sendProfessionValue = this.professionValue[0].name
          }
          if(this.dgreeValue.name){
            sendDgreeValue = this.dgreeValue.name
          }else{
            sendDgreeValue = this.dgreeValue[0].name
          }
          let data = {
            profession:sendProfessionValue,
            treatment_diseases:this.patientDiseasesValue,
            clinical_interest:this.clinicalIntersetValue,
            surgical_interest:this.surgicalIntersetValue,
            research_areas:JSON.stringify(this.sendResearchAreasValue),
            work_for:this.workAssociatedValue,
            degree:sendDgreeValue,
            experience:this.medicalProExperienceNamber,
            language:JSON.stringify(this.sendLanguageProficiencyValue),
            email:this.email,
            first_name:this.first_name,
            last_name:this.last_name,
            gender:this.gender,
            dob:this.dob,  
            mobile:this.phone.number,
            mobile_country_code:'+' + this.phone.country,
            signup_type:'doctor',
            area_of_work:this.workAssociatedValue,
            department:sendProfessionValue,
            professional_filter_category:sendProfessionalCategory,
            professional_filter_name:sendProfessionalFilterName,
           }
           if(this.other_doctor_cat != null && this.other_doctor_cat != ''){
             data.professional_category = this.other_doctor_cat.sub_category;
           }
          this.$store.dispatch('authUserSignupApiFun', data)
            .then((res) => {  
              let optSendData = {
                email: this.email,
                mobile_country_code:'+' + this.phone.country,
                mobile:this.phone.number
              }
              this.$store.dispatch('optCreateOnMobileApiFun', optSendData).then((res) => {
                toast.success(res.data.user_msg || res.data.msg);
                this.submitted =  false
                let passData = {
                  country:this.phone.country,
                  number:this.phone.number,
                  signup_type:'doctor'
                }
                bus.$emit('signUpFormMainBus', false);
                bus.$emit('signUpFormVerifyBus', true)
                bus.$emit('verfiyMobileInfoBus', passData);
                this.closeUpdateAboutUserInfoPopup()
              })
            }).catch((err) => {
              toast.error(err.data.data.user_msg || err.data.user_msg || err.data.msg);
              this.submitted =  false
            })
          return;
        }
      })
    },
    closeUpdateAboutUserInfoPopup(){
      document.body.classList.remove('popup_open_body')
      bus.$emit('addAboutPopupStateBus', false);
    },
  },
  watch: {
  },
}