/*eslint-disable */
import {bus} from '@/main';
export default {
  name: 'blurb-content-for-lymphomap-signup',
  components: {},
  props: [],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    openAddAboutPopup(){
      bus.$emit('addAboutPopupStateBus', true);
      bus.$emit('blurTextbPopupStateBus', false);
    },
    blurbTextPopupClosed(){
      bus.$emit('blurTextbPopupStateBus', false);
      document.body.classList.remove('popup_open_body')
    }
  }
}